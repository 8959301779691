<script>
import { getVideoPreviewUrl } from '@/api/data'

export default {
  name: 'VideoPreview',
  props: ['name'],
  data () {
    return {
      src: null
    }
  },
  created () {
    getVideoPreviewUrl({ name: this.name }).then(res => {
      if (res.code === 0) {
        this.src = res.data
      }
    })
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <video :src="src" style="width: 100%;height:400px;" controls autoplay></video>
  </div>
</template>

<style scoped lang='less'>

</style>
