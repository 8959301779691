<template>
  <div style="width: 100%;text-align: center;padding: 8px;">
    <img :src="url" style="max-height: 100%;max-width: 100%;">
  </div>
</template>

<script>
export default {
  name: 'ShowBigImage',
  props: ['url'],
  created () {

  }
}
</script>

<style scoped>

</style>
